import { Attributes } from '@opentelemetry/api';
import { ulid } from 'ulid';

import { OnlineUser } from '@common/types/online-user';

interface YjsCloudEventAttributes extends Attributes {
  // Required CloudEvents attributes (OpenTelemetry semantic conventions)
  'cloudevents.specversion': '1.0';
  'cloudevents.id': string;
  'cloudevents.source': string;
  'cloudevents.type': string;
  'cloudevents.time': string;

  // YJS specific extensions
  'cloudevents.reunite.yjs.guid': string;
  'cloudevents.reunite.yjs.client_id': number;
  'cloudevents.reunite.yjs.user.id': string;
  'cloudevents.reunite.yjs.user.name': string;
}

export class YjsEventMapper {
  private createSourceUri(docGuid: string): string {
    const host = window.location.host;
    return `ws://${host}/syncer/${docGuid}`;
  }

  mapToCloudEventAttributes(params: {
    eventType: string;
    docGuid: string;
    clientId: number;
    user: OnlineUser;
    attributes?: Record<string, unknown>;
    data?: Record<string, unknown>;
  }): YjsCloudEventAttributes {
    const { eventType, docGuid, clientId, attributes, user, data } = params;
    const sourceUri = this.createSourceUri(docGuid);

    return {
      'cloudevents.specversion': '1.0',
      'cloudevents.id': `evt_${ulid()}`,
      'cloudevents.source': sourceUri,
      'cloudevents.type': eventType,
      'cloudevents.time': new Date().toISOString(),

      'cloudevents.reunite.yjs.guid': docGuid,
      'cloudevents.reunite.yjs.client_id': clientId,
      'cloudevents.reunite.yjs.user.id': user.id,
      'cloudevents.reunite.yjs.user.name': user.name,
      ...this.toCloudEventAttributes(attributes ?? {}),
      ...(data && { 'cloudevents.data': JSON.stringify(data) }),
    };
  }

  /**
   * Converts an object to CloudEvents attributes format
   */
  toCloudEventAttributes(attributes: Record<string, unknown>): Record<string, string> {
    const result: Record<string, string> = {};
    for (const [key, value] of Object.entries(attributes)) {
      if (value === null || value === undefined) {
        continue;
      }
      const stringValue = typeof value === 'object' ? JSON.stringify(value) : String(value);
      const normalizedKey = key.replace(/_/g, '.');
      result[`cloudevents.${normalizedKey}`] = stringValue;
    }

    return result;
  }
}
