import { Context, context, Span, trace } from '@opentelemetry/api';

import { OnlineUser } from '@common/types/online-user';

import { YjsEventMapper } from './yjs-event-mapper';

export const YjsEventSource = {
  YJS: 'yjs',
  WS: 'ws',
  MONACO: 'monaco',
} as const;

export const YjsEventType = {
  // YJS Core Events
  SUBDOC_UPDATE: `${YjsEventSource.YJS}.subdoc.update`,
  ROOT_DOC_SYNCED: `${YjsEventSource.YJS}.root.document.synced`,
  SWITCH_ROOM: `${YjsEventSource.YJS}.switchRoom`,
  SUBDOCS_REMOVE: `${YjsEventSource.YJS}.subdocs.remove`,
  SUBDOCS_LOAD: `${YjsEventSource.YJS}.subdoc.load`,
  SUBDOC_CREATE: `${YjsEventSource.MONACO}.subdoc.create`,
  SUBDOC_MV: `${YjsEventSource.MONACO}.subdoc.mv`,
  SUBDOC_RM: `${YjsEventSource.MONACO}.subdoc.rm`,
  SUBDOC_UPLOAD: `${YjsEventSource.MONACO}.subdoc.upload`,

  // Monaco Events
  MONACO_UPDATE: `${YjsEventSource.YJS}.monaco.update`,

  // WebSocket Events
  WS_CONNECT: `${YjsEventSource.WS}.connect`,
  WS_DISCONNECT: `${YjsEventSource.WS}.disconnect`,
  WS_CONNECTION_ERROR: `${YjsEventSource.WS}.connection.error`,
  WS_CONNECTION_CLOSE: `${YjsEventSource.WS}.connection.close`,
  WS_CONNECTION_STATUS: `${YjsEventSource.WS}.connection.status`,
} as const;

interface EmitSpanEventProperties<T> {
  name: string;
  data?: T;
  docGuid?: string;
  attributes?: Record<string, string>;
}

export class YjsTelemetry {
  private static instance: YjsTelemetry | null = null;

  public sessionSpan?: Span;
  public enabled: boolean;

  private tracer = trace.getTracer('yjs-telemetry');

  private eventMapper: YjsEventMapper;
  private sessionContext?: Context;
  private clientId: number;
  private rootDocGuid: string;
  private user: OnlineUser;

  private constructor({
    enabled,
    clientId,
    rootDocGuid,
    user,
  }: {
    enabled: boolean;
    clientId: number;
    rootDocGuid: string;
    user: OnlineUser;
  }) {
    this.enabled = enabled;
    this.clientId = clientId;
    this.rootDocGuid = rootDocGuid;
    this.user = user;
    this.eventMapper = new YjsEventMapper();
  }

  static getInstance({
    enabled,
    clientId,
    rootDocGuid,
    user,
  }: {
    enabled: boolean;
    clientId: number;
    rootDocGuid: string;
    user: OnlineUser;
  }): YjsTelemetry {
    if (!YjsTelemetry.instance) {
      YjsTelemetry.instance = new YjsTelemetry({ enabled, clientId, rootDocGuid, user });
    }
    return YjsTelemetry.instance;
  }

  startDocumentSession() {
    if (!this.enabled) {
      return;
    }

    const attributes = this.eventMapper.mapToCloudEventAttributes({
      eventType: YjsEventType.WS_CONNECT,
      docGuid: this.rootDocGuid,
      clientId: this.clientId,
      user: this.user,
    });

    this.sessionSpan = this.tracer.startSpan(YjsEventType.WS_CONNECT, {
      attributes,
    });

    this.sessionContext = trace.setSpan(context.active(), this.sessionSpan);
    return this.sessionSpan;
  }

  endDocumentSession() {
    if (!this.enabled || !this.sessionSpan) {
      return;
    }
    this.emitSpan({
      name: YjsEventType.WS_DISCONNECT,
      docGuid: this.rootDocGuid,
    })?.end();
    this.sessionSpan = undefined;
    this.sessionContext = undefined;
  }

  emitSpan<T>({ name, docGuid, attributes, data }: EmitSpanEventProperties<T>): Span | undefined {
    try {
      if (!this.enabled || !this.eventMapper) {
        return;
      }

      const currentContext = this.sessionContext ?? context.active();

      return context.with(currentContext, () => {
        const cloudEventAttributes = this.eventMapper.mapToCloudEventAttributes({
          eventType: name,
          docGuid: docGuid ?? this.rootDocGuid,
          clientId: this.clientId,
          user: this.user,
          attributes,
          data: data as Record<string, T>,
        });

        return this.tracer.startSpan(name, {
          attributes: cloudEventAttributes,
        });
      });
    } catch (e) {
      console.error('Failed to emit span', e);
    }
  }
}
